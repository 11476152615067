/*@ngInject*/
function SearchSaveController(
  SETTINGS,
  jobSubmit,
  $mdDialog,
  $timeout,
  $http,
  _,
  userProfile,
  $log
) {
  let _url;

  this.submitting = false;
  this.submittedJob = null;
  this.submitErr = null;
  this.jobName = null;
  this.saveFromQueryBody = null;
  // Offline (non-ES) transformations to apply during saving
  this.pipeline = [];

  this.profile = userProfile;

  let submittedJobs = [];

  this.$onInit = () => {
    _url = `${SETTINGS.apiEndpoint}jobs/${this.job._id}/saveFromQuery`;
  };

  this.$onChanges = changeObj => {
    if (this.queryBody) {
      this.submitting = false;
      this.submittedJob = null;
      this.submitErr = null;
      this.jobName = null;

      this.saveFromQueryBody = angular.copy(this.queryBody);

      // TODO: implement effective tracker of submitted queries, across page refresh
      // TODO: improve performance?
      // @param arr: [ <Object> queryBody, <Object> submittedJob ]
      let equal = false;

      submittedJobs.forEach(arr => {
        if (equal) {
          return;
        }

        if (_.isEqual(arr[0], this.saveFromQueryBody)) {
          equal = true;
          this.submittedJob = arr[1];
        }
      });


      if (this.saveFromQueryBody.query.bool && this.saveFromQueryBody.query.bool.must) {
        if (this.saveFromQueryBody.query.bool.filter) {
          if (!Array.isArray(this.saveFromQueryBody.query.bool.filter)) {
            $log.error('Filter should be an array');

            // skip rewriting, the query is unexpected
          } else {
            this.saveFromQueryBody.query.bool.filter.push(this.saveFromQueryBody.query.bool.must);
            delete this.saveFromQueryBody.query.bool.must;
          }
        } else {
          this.saveFromQueryBody.query.bool.filter = [this.saveFromQueryBody.query.bool.must];
          delete this.saveFromQueryBody.query.bool.must;
        }
      }

      if (this.saveFromQueryBody.track_total_hits) {
        delete this.saveFromQueryBody.track_total_hits;
      }
    } else {
      this.saveFromQueryBody = null;
    }
  };

  const _submitAnnotationAsync = () => {
    if (this.jobName) {
      this.jobName = this.jobName.replace(/\s/g, "_");
    }

    $log.info("Submitting query to save", this.saveFromQueryBody);
    return $http
      .post(_url, {
        inputQueryBody: this.saveFromQueryBody,
        pipeline: this.pipeline || [],
        name: this.jobName
      })
      .then(response => jobSubmit.submitAsync(response.data));
  };

  this.saveFromQuery = () => {
    if (this.submitting || this.submittedJob) {
      return;
    }

    this.submitting = true;

    _submitAnnotationAsync()
      .then(submittedJob => {
        if (!submittedJob) {
          throw new Error("Job couldn't be submitted");
        }

        this.submittedJob = submittedJob;
        // Object.assign is not sufficient here, if queryBody has a sort key
        // (which references an array), the internal sort object will be mutated
        // even in our local copy
        submittedJobs.push([angular.copy(this.queryBody), this.submittedJob]);
      })
      .catch(err => {
        $log.warn(err);

        //catch error from _submitAnnotationAsync or catch !submittedJob error above
        // .data from response, .message if thrown error above
        this.submitErr = "Sorry, couldn't save. Please try again later";
      })
      .finally(() => {
        $timeout(() => {
          this.submitting = false;
        }, 150);
      });
  };
}

angular
  .module("sq.jobs.results.search.save.component", [
    "sq.user.auth",
    "sq.jobs.submit.service",
    "sq.user.profile.service"
  ])
  .component("sqSearchSave", {
    bindings: {
      // The submission object (could be search or main job submission)
      job: "<",
      queryBody: "<",
      pipeline: "<",
      onStarted: "&",
      onCompleted: "&",
      onFailed: "&"
    }, // isolate scope
    templateUrl: "jobs/results/search/jobs.results.search.save.tpl.html",
    controller: SearchSaveController,
    controllerAs: "$ctrl"
  });
